<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技跻身GDC全球创新项目TOP16，掀起AI创新热潮
              </p>
              <div class="fst-italic mb-2">2024年3月29日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >创新大赛</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/77/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    2024年全球开发者先锋大会（GDC）于3月23日-24日在上海成功举办，全球创新项目路演活动（线下）也如期而至。非凸科技荣幸成为TOP16优秀项目企业之一，受邀参加线下路演的终评环节。
                  </p>
                  <p>
                    作为世界人工智能大会（WAIC）聚焦科技和人才力量的重要板块，GDC已成为全球开发者的盛大节日、顶尖技术趋势的风向标。此次全球创新项目路演活动，吸引了近千家海内外优秀人工智能初创和成长型企业参与，同时获得了数百家投资机构积极关注。在初审的200家项目中，只有80家脱颖而出进入复审，最后甄选出16家优秀项目进入终评。
                  </p>
                  <p>
                    经过初审、复审的层层选拔，非凸科技凭借出色的产品性能、领先的技术创新以及突出的合作案例赢得了专家评审团的高度认可，最终成功晋级决赛。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/77/02-品牌官.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    在终评环节，非凸科技首席品牌官郑媛姿从团队背景、产品创新性、核心竞争力、市场战略、发展前景等纬度全面展示了企业项目，并与评审嘉宾进行了现场互动交流，探讨了行业机遇与挑战，为评审提供了更深入的了解与洞察。
                  </p>
                  <p>
                    16家企业在相互切磋的过程中，参赛者之间、参赛者与评委之间不断碰撞出新的思维火花，掀起了一波又一波关于AI创新应用的热烈讨论。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/77/03-现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    此次入选TOP16，代表着业界对非凸科技AI智能能力的充分认可，也将激励着非凸科技不断前行，坚持核心技术自主创新，推动数智交易能力在AI时代的持续迭代与升级。期待参赛企业在决赛中取得优异成绩。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News77",
};
</script>

<style></style>
